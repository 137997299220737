<template>
  <div class="about">
    <el-col :span="6">
      <div class="about-nav">
        <el-timeline>
          <el-timeline-item>
            <el-link href="#desc">{{ $t('about.desc') }}</el-link>
          </el-timeline-item>
          <el-timeline-item>
            <el-link href="#manual">{{ $t('about.manual') }}</el-link>
          </el-timeline-item>
          <el-timeline-item>
            <el-link href="#contact">{{ $t('about.contact') }}</el-link>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-col>
    <el-col :span="18">
      <div class="about-content">
        <el-row id="desc">
          <el-link href="#desc" icon="el-icon-link">{{ $t('about.desc') }}</el-link>
          <p>{{ $t('about.descContent') }}</p>
        </el-row>
        <el-row id="manual">
          <el-link href="#manual" icon="el-icon-link">{{ $t('about.manual') }}</el-link>
          <br />
          <br />
          <img src="@/assets/useManual.gif" alt="use Manual" />
          <br />
          <br />
        </el-row>
        <el-row id="contact">
          <el-link href="#contact" icon="el-icon-link">{{ $t('about.contact') }}</el-link>
          <p>
            {{ $t('about.contactTitle') }}
            <br />{{ $t('about.contactTel') }}
            <br />
            <a href="mailto:zhongrong1988@naver.com">{{ $t('common.email') }}: zhongrong1988@naver.com</a>
          </p>
        </el-row>
      </div>
    </el-col>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
  margin: 10px 30px;
  padding: 30px 0;

  .about-content {
    font-size: 14px;
    line-height: 1.5;
  }

  .el-timeline {
    padding-top: 4px;
    padding-left: 10px;
  }
}
</style>